import React from "react";

import SheetzContainerButton from "components/misc/button/SheetzContainerButton/SheetzContainerButton";
import SheetzRadioButtonContainer from "components/misc/form/SheetzRadio/SheetzRadioButtonContainer/SheetzRadioButtonContainer";

import { IconType, getIcon } from "util/Icon.util";
import { isInNativeMobileContext } from "util/MobileApp.util";
import {
  MobilePayType,
  MobilePaymentMethods,
  PaymentButtonStatus,
  SelectedMobilePaymentMethod,
} from "util/Payment.util";

interface MobilePaymentButtonProps {
  mobilePaymentMethods?: MobilePaymentMethods;
  onPaymentMethodClicked?: () => void;
  payInStore: boolean;
  selectedMobilePaymentMethod?: SelectedMobilePaymentMethod;
}

const MobilePaymentTypeButton = (props: MobilePaymentButtonProps) => {
  function getPaymentButtonStatus(): PaymentButtonStatus {
    let checked;
    let disabled = false;

    if (props.selectedMobilePaymentMethod?.paymentRestriction) {
      checked = false;
      disabled = true;
    } else {
      checked = props.selectedMobilePaymentMethod?.selected;
    }

    return { checked, disabled };
  }

  function getMobilePaymentMethod(): JSX.Element | undefined {
    if (isInNativeMobileContext()) {
      if (
        props.mobilePaymentMethods?.googlePayAvailable ||
        props.mobilePaymentMethods?.applePayAvailable
      ) {
        const paymentIcon =
          props.selectedMobilePaymentMethod?.mobilePayType === MobilePayType.ApplePay
            ? IconType.applepay
            : IconType.googlepay;

        const paymentType =
          props.selectedMobilePaymentMethod?.mobilePayType === MobilePayType.ApplePay
            ? "Apple Pay"
            : "Google Pay";

        return (
          <>
            <SheetzRadioButtonContainer lightTheme>
              <input
                type="radio"
                id="mobilePaymentType"
                checked={getPaymentButtonStatus().checked}
                disabled={getPaymentButtonStatus().disabled}
              />

              <label htmlFor="mobilePaymentType" className="payment-type-flex-container">
                <span>
                  <div className="payment-type-flex-container">
                    {getIcon(paymentIcon, "payment-method-icon")}
                    <p className="payment-type">{paymentType}</p>
                  </div>
                </span>
              </label>
            </SheetzRadioButtonContainer>
          </>
        );
      }
    }

    return undefined;
  }

  return (
    <>
      {isInNativeMobileContext() && !props.payInStore && props.mobilePaymentMethods !== undefined && (
        <SheetzContainerButton
          className="payment-info-container mobile-payment"
          onClick={
            !props.selectedMobilePaymentMethod?.paymentRestriction
              ? props.onPaymentMethodClicked
              : undefined
          }
        >
          {getMobilePaymentMethod()}
        </SheetzContainerButton>
      )}
    </>
  );
};

export default MobilePaymentTypeButton;
