import axios, { AxiosError } from "axios";
import mapboxgl from "mapbox-gl";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { BFFErrorResponse } from "axiosConfig";

import "./StoreDetails.scss";

import { StoreLocation } from "assets/dtos/anywhere-dto";

import Header, { HeaderType } from "components/layout/Header/Header";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";
import { DefaultMapZoom } from "components/pages/FindASheetz/FindASheetz";
import StoreDetailsModal from "components/pages/FindASheetz/StoreDetailsModal/StoreDetailsModal";

import { useMediaQuery } from "hooks";

import { AppContext, initiateStoreDetailsMapMediaQuery } from "util/AppContext.util";
import { sheetzMapBoxAccessToken } from "util/Geolocation.util";
import { getStore } from "util/Order.util";

const FindASheetz = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const { storeId } = useParams<{ storeId: string }>();
  const [map, setMap] = useState<mapboxgl.Map>();
  const [initiateStoreDetailsMap] = useMediaQuery(initiateStoreDetailsMapMediaQuery);
  const [storeDetailLocation, setStoreDetailLocation] = useState<StoreLocation | undefined>(
    undefined
  );

  const headerTitle = `Store #${storeId}`;
  const mapContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!storeId) {
      return;
    }

    if (!storeDetailLocation) {
      appContext.showLoading();

      getStore(parseInt(storeId), true)
        .then((response) => {
          setStoreDetailLocation(response.data.store);
        })
        .catch((error: Error | AxiosError<BFFErrorResponse>): void => {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 400) {
              navigate("/findASheetz", { replace: true });
              appContext.showToast(
                "Oh Sheetz!",
                "There was a problem retrieving that store. Are you sure that was the correct store number?",
                ToastType.error
              );
            }
          }
        })
        .finally(() => {
          appContext.hideLoading();
        });
    }
  }, [appContext, navigate, storeDetailLocation, storeId]);

  useEffect(() => {
    if (storeDetailLocation && initiateStoreDetailsMap) {
      const createMap = (mapContainer: React.RefObject<HTMLDivElement>): void => {
        if (!mapContainer.current) {
          return;
        }

        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/streets-v11",
          center: [storeDetailLocation.longitude, storeDetailLocation.latitude],
          zoom: DefaultMapZoom,
          maxZoom: 18,
          accessToken: sheetzMapBoxAccessToken,
        });

        setMap(map);
      };

      if (!map) {
        createMap(mapContainer);
      }
    }
  }, [initiateStoreDetailsMap, map, storeDetailLocation]);

  return (
    <>
      <Header type={HeaderType.main} title={headerTitle} fixed={true} />

      <div className="store-details-wrapper">
        <div className="map-overlay"></div>
        <div ref={mapContainer} className="map-container"></div>

        {storeDetailLocation && (
          <div className="store-details">
            <StoreDetailsModal storeAttribute={storeDetailLocation} noScroll />
          </div>
        )}
      </div>
    </>
  );
};

export default FindASheetz;
