import React, { PropsWithChildren } from "react";

import "./ErrorMessage.scss";

import { IconType, getIcon } from "util/Icon.util";

type ErrorMessageType = "WARNING";

interface ErrorMessageProps {
  message?: string;
  title?: string;
  type: ErrorMessageType;
}

const ErrorMessage = ({ message, title, type, children }: PropsWithChildren<ErrorMessageProps>) => {
  let icon;

  // Only one type right now but will likely add more in the future.
  switch (type) {
    case "WARNING":
      icon = IconType.alertWarning;
      break;
    default:
      icon = IconType.alertWarning;
  }

  return (
    <div className="error-message-container">
      <div className="error-message-icon">{getIcon(icon, "alert-icon")}</div>

      <div className="error-message-content">
        {title && <h1>{title}</h1>}
        {message && <p>{message}</p>}
        {children}
      </div>
    </div>
  );
};

export default ErrorMessage;
