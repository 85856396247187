import classNames from "classnames";
import React from "react";

import "./NoTipActionSheet.scss";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { isInNativeMobileContext } from "util/MobileApp.util";

interface NoTipActionSheetProps {
  showNoTipActionSheet: boolean;
  setShowNoTipActionSheet: (show: boolean) => void;
  setShowOtherTipAmountActionSheet: (show: boolean) => void;
}

const NoTipActionSheet = ({
  setShowNoTipActionSheet,
  showNoTipActionSheet,
  setShowOtherTipAmountActionSheet,
}: NoTipActionSheetProps) => {
  const buttonClasses = classNames("update-my-tip", {
    "in-mobile-app": isInNativeMobileContext(),
  });

  return (
    <ActionSheet
      color={ActionSheetColor.red}
      title="No Tip?"
      overlay={true}
      shouldDisplay={showNoTipActionSheet}
    >
      <div className="no-tip-container">
        <p className="tip-instructions">
          Are you sure you want to leave no tip? This can cause slower delivery or no delivery at
          all. Adding a tip helps drivers deliver your shtuff faster!
        </p>

        <div>
          <SheetzButton
            label="Close"
            transparentDark
            onClick={(): void => {
              setShowNoTipActionSheet(false);
            }}
          />
          <SheetzButton
            label="Update my tip"
            className={buttonClasses}
            onClick={(): void => {
              setShowNoTipActionSheet(false);
              setShowOtherTipAmountActionSheet(true);
            }}
          />
        </div>
      </div>
    </ActionSheet>
  );
};

export default NoTipActionSheet;
