import AdditionalPaymentActions from "./AdditionalPaymentActions/AdditionalPaymentActions";
import SubmitOrderButton from "./SubmitOrderButton/SubmitOrderButton";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

import "./PaymentFooter.scss";

import {
  DeliveryTip,
  MobilePayment,
  PaymentMethod,
  PurchaseOrder,
  PurchaseOrderIntegratedDelivery,
} from "assets/dtos/anywhere-dto";

import MobilePaymentTypeButton from "components/Order/PaymentFooter/MobilePaymentTypeButton/MobilePaymentTypeButton";
import { TipChoice } from "components/Order/PaymentFooter/PaymentFooterTipChoice/PaymentFooterTipChoice";
import PaymentFooterTipManager from "components/Order/PaymentFooter/PaymentFooterTipMananger/PaymentFooterTipManager";
import PaymentMethodErrorManager from "components/Order/PaymentFooter/PaymentMethodErrorManager/PaymentMethodErrorManager";
import PaymentTypeButton from "components/Order/PaymentFooter/PaymentTypeButton/PaymentTypeButton";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import InlineLoading from "components/misc/indicators/Loading/InlineLoading";

import { useMediaQuery } from "hooks";

import { orderConfirmationDesktopMediaQuery } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import { getMobilePaymentMethods, isInNativeMobileContext } from "util/MobileApp.util";
import {
  MobilePayType,
  MobilePaymentMethods,
  SelectedMobilePaymentMethod,
  SelectedPaymentMethod,
  getPaymentMethods,
} from "util/Payment.util";
import { getSelectedPaymentMethodId } from "util/Storage.util";

interface PaymentFooterProps {
  fetchPaymentMethods: boolean;
  fetchMobilePaymentMethods?: boolean;
  subtotal?: number;
  discountTotal?: number;
  virtualDiscountTotal?: number;
  taxTotal?: number;
  total?: number;
  orderConfirmation?: boolean;
  orderSubmitting?: boolean;
  paymentMethodMobileRefresh?: boolean;
  paymentMethod?: PaymentMethod;
  purchaseOrder?: PurchaseOrder;
  resetPurchaseOrder?: () => void;
  isReloadGiftCard?: boolean;
  integratedDelivery?: PurchaseOrderIntegratedDelivery;
  deliveryTipChoice?: TipChoice;
  deliveryTipSuggestions?: DeliveryTip[];
  onChangePaymentPressed?: () => void;
  onResetPurchaseOrder?: () => void;
  onScrollToDetails?: () => void;
  onSubmitButtonPressed?: (paymentMethod?: PaymentMethod, mobilePayment?: MobilePayment) => void;
  onTipSelected?: (tipChoice: TipChoice) => void;
  showDetailsLink?: boolean;
}

const PaymentFooter = (props: PaymentFooterProps) => {
  const [useDesktopView] = useMediaQuery(orderConfirmationDesktopMediaQuery);
  const [creditCardExpired, setCreditCardExpired] = useState(true);
  const [giftCardHasInsufficientFunds, setGiftCardHasInsufficientFunds] = useState(true);
  const [mobilePaymentMethods, setMobilePaymentMethods] = useState<MobilePaymentMethods>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
  const [paymentMethodErrorManagerRefresh, setPaymentMethodErrorManagerRefresh] = useState<
    boolean | undefined
  >();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<SelectedPaymentMethod>();
  const [selectedMobilePaymentMethod, setSelectedMobilePaymentMethod] =
    useState<SelectedMobilePaymentMethod>();
  const [showErrorLoadingPayments, setShowErrorLoadingPayments] = useState<boolean>(false);
  const [showLoadingPaymentMethods, setShowLoadingPaymentMethods] = useState<boolean>(false);

  const payInStore = !props.fetchPaymentMethods && !props.paymentMethod;
  const isDelivery = !!props.integratedDelivery;
  const itemTotal = props.subtotal !== undefined ? props.subtotal - (props.discountTotal || 0) : 0;

  /**
   * Fetch payment methods from server - this does not fetch mobile payments
   * as those are stored on the device itself.
   * Dependency: props.paymentMethodMobileRefresh
   */
  useEffect(() => {
    if (props.fetchPaymentMethods) {
      /**
       * User has interacted with the native wallet and had returned.
       *
       * The requirement is to have one payment method show after the user has selected it
       * so, we clear all mobile payment methods and the selected payment and mobile payment methods.
       */
      if (isInNativeMobileContext() && props.paymentMethodMobileRefresh !== undefined) {
        /**
         * A user can select a traditional payment method. This will give the paymentMethodErrorManagerRefresh a value
         * rather than undefined and if the user then goes back to their wallet and selects a mobile payment method,
         * the PaymentMethodErrorManager useEffect will skip any error checking.
         */
        setPaymentMethodErrorManagerRefresh(undefined);

        handleNativeWalletPaymentChange();
        fetchPaymentMethods();
      } else {
        fetchPaymentMethods();
        getAndSetMobilePaymentMethods();
      }
    } else if (props.paymentMethod) {
      /**
       * This block won't happen in the OrderConfirmation screen.
       */

      setSelectedPaymentMethod({
        paymentMethod: props.paymentMethod,
        selected: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paymentMethodMobileRefresh]);

  /**
   * Determine traditional payment methods and if credit card is expired or gift card has insufficient funds.
   * Dependency: selectedPaymentMethod, props.total
   */
  useEffect(() => {
    if (paymentMethods !== undefined && props.total !== undefined) {
      const isCreditCardPayment =
        selectedPaymentMethod !== undefined &&
        selectedPaymentMethod.paymentMethod?.paymentType === "CREDIT_CARD" &&
        selectedPaymentMethod?.paymentMethod.creditCard !== undefined;

      const isGiftCardPayment =
        selectedPaymentMethod !== undefined &&
        selectedPaymentMethod.paymentMethod?.paymentType === "ZCARD" &&
        selectedPaymentMethod.paymentMethod.zCard !== undefined;

      // Gift card
      if (isGiftCardPayment) {
        if (selectedPaymentMethod?.paymentMethod?.zCard?.balance !== undefined) {
          setGiftCardHasInsufficientFunds(
            selectedPaymentMethod.paymentMethod?.zCard?.balance < props.total
          );
        }
      } else {
        setGiftCardHasInsufficientFunds(false);
      }

      // Credit Card
      if (isCreditCardPayment) {
        if (
          selectedPaymentMethod?.paymentMethod &&
          selectedPaymentMethod?.paymentMethod.creditCard?.isExpired !== undefined
        ) {
          setCreditCardExpired(selectedPaymentMethod?.paymentMethod.creditCard?.isExpired);
        }
      } else {
        setCreditCardExpired(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod?.paymentMethod, props.total, showLoadingPaymentMethods]);

  /**
   * Check purchase order for payment restrictions
   * Dependency: props.purchaseOrder, selectedPaymentMethod?.selected, selectedMobilePaymentMethod?.selected
   */
  useEffect(() => {
    const paymentRestrictions = checkPurchaseOrderForPaymentRestrictions();

    // No payment errors exist, select one of the payment methods
    if (!creditCardExpired && !giftCardHasInsufficientFunds && !paymentRestrictions) {
      // Are we in mobile?
      if (isInNativeMobileContext()) {
        // Only select a payment method if none are selected.
        // Otherwise, there will be a render loop.
        if (!selectedMobilePaymentMethod?.selected && !selectedPaymentMethod?.selected) {
          // Select the mobile payment method if it set to the default or if there are no traditional payment methods.
          // Otherwise, select the traditional payment method.
          if (
            (selectedMobilePaymentMethod && selectedMobilePaymentMethod?.default) ||
            (selectedMobilePaymentMethod && paymentMethods?.length === 0)
          ) {
            setSelectedMobilePaymentMethod({
              ...selectedMobilePaymentMethod,
              selected: true,
            });
          } else if (selectedPaymentMethod && !selectedPaymentMethod?.selected) {
            setSelectedPaymentMethod({
              ...selectedPaymentMethod,
              selected: true,
            });
          }
        }
      } else {
        // Only select a payment method if none are selected.
        // Otherwise, there will be a render loop.
        if (selectedPaymentMethod && !selectedPaymentMethod?.selected) {
          setSelectedPaymentMethod({
            ...selectedPaymentMethod,
            selected: true,
          });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.purchaseOrder,
    creditCardExpired,
    giftCardHasInsufficientFunds,
    paymentMethods,
    selectedPaymentMethod?.selected,
    selectedMobilePaymentMethod?.selected,
  ]);

  function changePaymentMethod(): void {
    props.onChangePaymentPressed?.();

    /**
     * We can't set paymentMethodErrorManagerRefresh in the mobile apps because a user
     * can select a payment that may have payment restrictions, so we have to run the error methods
     * to determine every time they select a new payment method. We do not allow users to select
     * payment methods with payment restrictions in the wallet on the web.
     */
    if (!isInNativeMobileContext()) {
      const currentValue =
        paymentMethodErrorManagerRefresh !== undefined ? !paymentMethodErrorManagerRefresh : false;

      setPaymentMethodErrorManagerRefresh(currentValue);
    }
  }

  /** Loop through paymentRestrictions and determine if it matches either
   * the selectedPaymentMethod or selectedMobilePaymentMethod.
   */
  function checkPurchaseOrderForPaymentRestrictions(): boolean {
    let paymentRestrictions = false;

    if (
      props.purchaseOrder?.paymentRestrictions &&
      props.purchaseOrder?.paymentRestrictions.length > 0
    ) {
      props.purchaseOrder?.paymentRestrictions.forEach((paymentRestriction) => {
        if (paymentRestriction.paymentType === selectedPaymentMethod?.paymentMethod?.paymentType) {
          setSelectedPaymentMethod({
            ...selectedPaymentMethod,
            paymentRestriction: paymentRestriction,
          });

          paymentRestrictions = true;
        }

        if (paymentRestriction.paymentType === selectedMobilePaymentMethod?.mobilePayType) {
          setSelectedMobilePaymentMethod({
            ...selectedMobilePaymentMethod,
            paymentRestriction: paymentRestriction,
          });

          paymentRestrictions = true;
        }
      });
    }

    return paymentRestrictions;
  }

  function fetchPaymentMethods(): void {
    const selectedPaymentMethodId = getSelectedPaymentMethodId();

    setShowLoadingPaymentMethods(true);

    getPaymentMethods()
      .then(
        (response) => {
          const paymentMethods = response.data.paymentMethods;

          let paymentMethod: PaymentMethod;

          if (selectedPaymentMethodId !== null) {
            paymentMethod =
              paymentMethods.find(
                (paymentMethod) =>
                  parseInt(selectedPaymentMethodId) === paymentMethod.paymentMethodId
              ) || paymentMethods[0];
          } else {
            paymentMethod =
              paymentMethods.find((paymentMethod) => paymentMethod.isDefault) || paymentMethods[0];
          }

          setShowErrorLoadingPayments(false);
          setPaymentMethods(response.data.paymentMethods);

          if (paymentMethod) {
            setSelectedPaymentMethod({
              paymentMethod: paymentMethod,
              selected: false,
            });
          }
        },
        () => setShowErrorLoadingPayments(true)
      )
      .finally(() => setShowLoadingPaymentMethods(false));
  }

  /**
   * Once the user closes the native wallet, OrderConfirmation updates the value of
   * paymentMethodMobileRefresh which triggers this to fetch the new payment method
   * based on the selectedPaymentMethodId in sessionStorage.
   */
  function handleNativeWalletPaymentChange(): void {
    const selectedPaymentMethodId = getSelectedPaymentMethodId();

    setSelectedPaymentMethod(undefined);

    if (props.fetchMobilePaymentMethods) {
      /**
       * If the user has updated their payment method through a bridge method,
       * check to see if we have received a "-2" (Google Pay) 0r "-3" (Apple Pay)
       * and set that as the default payment and don't show another payment type.
       */
      if (selectedPaymentMethodId === "-2" || selectedPaymentMethodId === "-3") {
        setMobilePaymentMethods({
          applePayAvailable: selectedPaymentMethodId === "-3",
          applePayDefault: selectedPaymentMethodId === "-3",
          googlePayAvailable: selectedPaymentMethodId === "-2",
          googlePayDefault: selectedPaymentMethodId === "-2",
        });

        setSelectedMobilePaymentMethod({
          default: true,
          mobilePayType:
            selectedPaymentMethodId === "-2" ? MobilePayType.GooglePay : MobilePayType.ApplePay,
          selected: true,
        });

        return;
      } else {
        setMobilePaymentMethods(undefined);
        setSelectedMobilePaymentMethod(undefined);
      }
    }
  }

  function paymentMethodClicked(mobile: boolean): void {
    if (isInNativeMobileContext() && selectedMobilePaymentMethod) {
      setSelectedPaymentMethod({ ...selectedPaymentMethod, selected: !mobile });
      setSelectedMobilePaymentMethod({ ...selectedMobilePaymentMethod, selected: mobile });
    } else {
      props.onChangePaymentPressed?.();
    }

    /**
     * We can't set paymentMethodErrorManagerRefresh in the mobile apps because a user
     * can select a payment that may have payment restrictions, so we have to run the error methods
     * to determine every time they select a new payment method. We do not allow users to select
     * payment methods with payment restrictions in the wallet on the web.
     */
    const currentValue =
      paymentMethodErrorManagerRefresh !== undefined ? !paymentMethodErrorManagerRefresh : false;

    setPaymentMethodErrorManagerRefresh(currentValue);
  }

  /**
   * Check for mobile payment methods from individual mobile device - these are
   * not stored on the server.
   */
  function getAndSetMobilePaymentMethods(): void {
    if (isInNativeMobileContext() && props.fetchMobilePaymentMethods) {
      const mobilePaymentMethodsFromDevice = getMobilePaymentMethods();

      if (mobilePaymentMethodsFromDevice) {
        const paymentMethods = JSON.parse(mobilePaymentMethodsFromDevice);
        setMobilePaymentMethods(paymentMethods);

        if (paymentMethods?.applePayAvailable) {
          setSelectedMobilePaymentMethod({
            default: paymentMethods?.applePayDefault,
            mobilePayType: MobilePayType.ApplePay,
            selected: false,
          });
        }

        if (paymentMethods?.googlePayAvailable) {
          setSelectedMobilePaymentMethod({
            default: paymentMethods?.googlePayDefault,
            mobilePayType: MobilePayType.GooglePay,
            selected: false,
          });
        }
      }
    }
  }

  function tipSelected(tip: TipChoice): void {
    props.onTipSelected?.(tip);
  }

  const conditionalDeliveryClassName = { delivery: props.integratedDelivery !== undefined };
  const conditionalInStoreClassName = { "pay-in-store": payInStore };
  const paymentFooterClasses = classNames(
    "payment-footer",
    conditionalDeliveryClassName,
    conditionalInStoreClassName,
    {
      "in-mobile-app": isInNativeMobileContext(),
      "order-confirmation": props.orderConfirmation,
    }
  );

  /**
   * Show different state of payment footer based on payment method status.
   */
  if (props.fetchPaymentMethods && !paymentMethods?.length && !selectedMobilePaymentMethod) {
    // There are no payment methods fetched.
    const loadingPaymentMethodsClasses = classNames(
      "payment-footer loading-payment-methods",
      conditionalDeliveryClassName,
      conditionalInStoreClassName
    );

    if (showLoadingPaymentMethods) {
      // Payment methods are being fetched, show loading state.
      return (
        <div className={loadingPaymentMethodsClasses}>
          <InlineLoading hideBackground={true} size={100}>
            {getIcon(IconType.card, "card-icon")}
          </InlineLoading>
          <p>Loading Payment</p>
        </div>
      );
    } else if (showErrorLoadingPayments) {
      // There was an error fetching the payment methods, show error state.
      const errorLoadingPaymentMethodsClasses = classNames(
        "payment-footer error-loading-payments",
        conditionalDeliveryClassName,
        conditionalInStoreClassName
      );

      return (
        <div className={errorLoadingPaymentMethodsClasses}>
          <div className="error-container">
            <div className="error-message">
              {getIcon(IconType.exclamationCircle, "exclamation-circle-icon")}
              <p>Payment error - Please refresh</p>
            </div>
            <SheetzButton
              borderColor="light-border"
              label="Refresh"
              label2={getIcon(IconType.refresh, "refresh-icon")}
              onClick={() => fetchPaymentMethods()}
              className="refresh"
            />
          </div>
        </div>
      );
    } else if (paymentMethods?.length === 0 && !selectedMobilePaymentMethod) {
      // No payment methods exist.
      const noLoadingPaymentMethodsClasses = classNames(
        "payment-footer no-payment-methods",
        conditionalDeliveryClassName,
        conditionalInStoreClassName
      );

      return (
        <div className={noLoadingPaymentMethodsClasses}>
          <div className="no-payment-methods-container">
            <p className="instructions">To complete this purchase,</p>
            <SheetzButton
              label="Add Payment Method"
              onClick={changePaymentMethod}
              borderColor="light-border"
            />
          </div>
        </div>
      );
    } else {
      return <div className={paymentFooterClasses} />;
    }
  } else if (props.orderSubmitting) {
    const submittingPaymentMethodsClasses = classNames(
      "payment-footer submitting-order",
      conditionalDeliveryClassName,
      conditionalInStoreClassName
    );

    return (
      <div className={submittingPaymentMethodsClasses}>
        <InlineLoading hideBackground={true} size={100}>
          {getIcon(IconType.burger, "burger-icon")}
        </InlineLoading>
        <p>Submitting Order</p>
      </div>
    );
  }

  // Default rendering state where the user has payment methods loaded, or is paying in store.
  return (
    <div className={paymentFooterClasses}>
      {isDelivery && props.deliveryTipSuggestions && (
        <PaymentFooterTipManager
          itemTotal={itemTotal}
          deiveryTipSuggestions={props.deliveryTipSuggestions}
          onTipSelected={tipSelected}
          otherAmount={
            props.integratedDelivery?.deliveryTipPercentage
              ? undefined
              : props.integratedDelivery?.deliveryTip
          }
          tipChoice={props.deliveryTipChoice}
        />
      )}

      <div className="payment-details-container">
        <MobilePaymentTypeButton
          mobilePaymentMethods={mobilePaymentMethods}
          onPaymentMethodClicked={() => paymentMethodClicked(true)}
          payInStore={payInStore}
          selectedMobilePaymentMethod={selectedMobilePaymentMethod}
        />

        <PaymentTypeButton
          fetchPaymentMethods={props.fetchPaymentMethods}
          isReloadGiftCard={props.isReloadGiftCard}
          giftCardHasInsufficientFunds={giftCardHasInsufficientFunds}
          onPaymentMethodClicked={() => paymentMethodClicked(false)}
          payInStore={payInStore}
          selectedPaymentMethod={selectedPaymentMethod}
        />
      </div>

      {!showLoadingPaymentMethods && (
        <PaymentMethodErrorManager
          changePaymentMethod={changePaymentMethod}
          creditCardExpired={creditCardExpired}
          giftCardHasInsufficientFunds={giftCardHasInsufficientFunds}
          isReloadGiftCard={props.isReloadGiftCard}
          payInStore={payInStore}
          paymentMethodErrorManagerRefresh={paymentMethodErrorManagerRefresh}
          paymentMethods={paymentMethods}
          purchaseOrder={props.purchaseOrder}
          selectedMobilePaymentMethod={selectedMobilePaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
        >
          <AdditionalPaymentActions
            changePaymentMethod={changePaymentMethod}
            payInStore={payInStore}
            showDetailsLink={props.showDetailsLink}
            viewPriceDetails={props.showDetailsLink ? props.onScrollToDetails : undefined}
          />

          <SubmitOrderButton
            creditCardExpired={creditCardExpired}
            isReloadGiftCard={props.isReloadGiftCard}
            giftCardHasInsufficientFunds={giftCardHasInsufficientFunds}
            onSubmitButtonPressed={props.onSubmitButtonPressed}
            payInStore={payInStore}
            purchaseOrder={props.purchaseOrder}
            selectedMobilePaymentMethod={selectedMobilePaymentMethod}
            selectedPaymentMethod={selectedPaymentMethod}
            total={props.total}
          />

          {props.orderConfirmation && (
            <>
              {useDesktopView && (
                <SheetzButton
                  className="continue-ordering-button"
                  label="Continue Ordering"
                  type="button"
                  transparentLight
                  onClick={(): void => props.resetPurchaseOrder?.()}
                />
              )}
            </>
          )}
        </PaymentMethodErrorManager>
      )}
    </div>
  );
};

export default PaymentFooter;
