import classNames from "classnames";
import { Formik, FormikProps } from "formik";
import React, { ReactElement, useContext, useEffect, useState } from "react";

import "./DriverType.scss";

import { GetDriverTypesResponse, UpdateDriverTypesRequest } from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ButtonFooterContainer from "components/misc/containers/ButtonFooterContainer/ButtonFooterContainer";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import SheetzCheckbox from "components/misc/form/SheetzCheckbox/SheetzCheckbox";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { getDriverTypes, updateDriverTypes } from "util/Account.util";
import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";

const DriverType = () => {
  const appContext = useContext(AppContext);
  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;

  const [initialFormValues, setInitialFormValues] = useState<GetDriverTypesResponse>({
    includeAutoDieselDriverType: false,
    includeTruckDieselDriverType: false,
    includeAutoDriverType: false,
    includeAutoEVDriverType: false,
  });

  useEffect(() => {
    showLoading();

    getDriverTypes()
      .then((response) => {
        setInitialFormValues(response.data);
      })
      .finally(hideLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addDriverTypes(values: UpdateDriverTypesRequest): void {
    showLoading();

    updateDriverTypes(values)
      .then(() => {
        setInitialFormValues(values);
        appContext.showToast("Settings Saved!", "", ToastType.success);
      })
      .finally(hideLoading);
  }

  return (
    <div className="driver-type">
      <ResponsiveLayoutContainer className="account-settings">
        <p>
          The following selection is used to provide you with relevant dealz, offerz, and features
          based on your activity.
        </p>

        <p className="help-text">Select all that apply.</p>

        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          onSubmit={(values: UpdateDriverTypesRequest): void => addDriverTypes(values)}
        >
          {(props: FormikProps<UpdateDriverTypesRequest>): ReactElement => (
            <form onSubmit={props.handleSubmit}>
              <label htmlFor="includeAutoDriverType" className="driver-type-checkbox">
                <div
                  className={classNames("vehicle-flex-container", {
                    selected: props.values.includeAutoDriverType,
                  })}
                >
                  <SheetzCheckbox name="includeAutoDriverType" />
                  <div className="driver-type-icon-container">
                    {getIcon(IconType.car, "driver-type-icon car")}
                  </div>
                  <div className="driver-type-label">Auto</div>
                </div>
              </label>

              <label htmlFor="includeAutoEVDriverType" className="driver-type-checkbox">
                <div
                  className={classNames("vehicle-flex-container", {
                    selected: props.values.includeAutoEVDriverType,
                  })}
                >
                  <SheetzCheckbox name="includeAutoEVDriverType" />
                  <div className="driver-type-icon-container">
                    {getIcon(IconType.carEv, "driver-type-icon")}
                  </div>
                  <div className="driver-type-label">Auto - EV Charging</div>
                </div>
              </label>

              <label htmlFor="includeAutoDieselDriverType" className="driver-type-checkbox">
                <div
                  className={classNames("vehicle-flex-container", {
                    selected: props.values.includeAutoDieselDriverType,
                  })}
                >
                  <SheetzCheckbox name="includeAutoDieselDriverType" />
                  <div className="driver-type-icon-container">
                    {getIcon(IconType.autoDiesel, "driver-type-icon")}
                  </div>
                  <div className="driver-type-label">Auto - Diesel</div>
                </div>
              </label>

              <label htmlFor="includeTruckDieselDriverType" className="driver-type-checkbox">
                <div
                  className={classNames("vehicle-flex-container", {
                    selected: props.values.includeTruckDieselDriverType,
                  })}
                >
                  <SheetzCheckbox name="includeTruckDieselDriverType" />
                  <div className="driver-type-icon-container">
                    {getIcon(IconType.truckDiesel, "driver-type-icon")}
                  </div>
                  <div className="driver-type-label">Truck - Diesel</div>
                </div>
              </label>

              <ButtonFooterContainer>
                <SheetzButton borderColor="light-border" disabled={!props.dirty} label="Save" />
              </ButtonFooterContainer>
            </form>
          )}
        </Formik>
      </ResponsiveLayoutContainer>
    </div>
  );
};

export default DriverType;
