import axios, { AxiosError } from "axios";
import classNames from "classnames";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";

import { BFFErrorResponse } from "axiosConfig";

import "./OrderConfirmation.scss";

import {
  DeliveryAddress,
  DeliveryTip,
  GetUserProfileResponse,
  MobilePayment,
  Payment,
  PaymentMethod,
  PurchaseOrderHeader,
  PurchaseOrderItem,
  PurchaseOrderLoyaltyDiscount,
  SubmitOrderRequest,
  UpdatePurchaseOrderRequest,
  UserVehicleResponse,
} from "assets/dtos/anywhere-dto";

import MyOrder from "components/Order/MyOrder/MyOrder";
import MyPickupInfo from "components/Order/MyPickupInfo/MyPickupInfo";
import { OrderSubviewProps } from "components/Order/Order";
import CurbsideInfoActionSheet from "components/Order/OrderConfirmation/ActionSheets/CurbsideInfoActionSheet/CurbsideInfoActionSheet";
import CustomTipActionSheet from "components/Order/OrderConfirmation/ActionSheets/CustomTipActionSheet/CustomTipActionSheet";
import NoTipActionSheet from "components/Order/OrderConfirmation/ActionSheets/NoTipActionSheet/NoTipActionSheet";
import AvailableRewardsButton from "components/Order/OrderConfirmation/AvailableRewardsButton/AvailableRewardsButton";
import OrderConfirmationDetails from "components/Order/OrderConfirmation/OrderConfirmationDetails/OrderConfirmationDetails";
import OrderCurbsideDetails from "components/Order/OrderConfirmation/OrderCurbsideDetails/OrderCurbsideDetails";
import OrderDeliveryInstructions from "components/Order/OrderConfirmation/OrderDeliveryInstructions/OrderDeliveryInstructions";
import OrderDeliveryPhone from "components/Order/OrderConfirmation/OrderDeliveryPhone/OrderDeliveryPhone";
import OrderRetry from "components/Order/OrderConfirmation/OrderRetry/OrderRetry";
import OrderTotals from "components/Order/OrderTotals/OrderTotals";
import PaymentFooter from "components/Order/PaymentFooter/PaymentFooter";
import { TipChoice } from "components/Order/PaymentFooter/PaymentFooterTipChoice/PaymentFooterTipChoice";
import RestrictedItemActionSheet from "components/Order/RestrictedItem/RestrictedItemActionSheet/RestrictedItemActionSheet";
import RestrictedItemDeliveryTermsActionSheet from "components/Order/RestrictedItem/RestrictedItemDeliveryTermsActionSheet/RestrictedItemDeliveryTermsActionSheet";
import RestrictedItemPickupTermsActionSheet from "components/Order/RestrictedItem/RestrictedItemPickupTermsActionSheet/RestrictedItemPickupTermsActionSheet";
import SheetzContainerButton from "components/misc/button/SheetzContainerButton/SheetzContainerButton";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { useMediaQuery } from "hooks";

import { getUserProfile } from "util/Account.util";
import { AppContext, orderConfirmationDesktopMediaQuery } from "util/AppContext.util";
import { clearOrderSession } from "util/Authentication.util";
import {
  ShoppingBagItem,
  isCustomizedShoppingBagItem,
  isShoppingBagCombo,
  isShoppingBagItem,
} from "util/Bag.util";
import { IconType, getIcon } from "util/Icon.util";
import {
  finishMobileOrder,
  isInNativeMobileContext,
  showNativeMobileLoadingIndicator,
  showNativeWalletInterface,
} from "util/MobileApp.util";
import { UserVehicle, getUserVehicles } from "util/MyVehicles.util";
import {
  CustomizedItem,
  getTipPercentageFromChoice,
  isCustomizedItem,
  submitOrder,
  suggestedDeliveryTipPercentages,
  updatePurchaseOrder,
} from "util/Order.util";
import getOrGenerateRavelinId from "util/Ravelin.util";
import { ReorderedShoppingBagItem, isReorderedShoppingBagItem } from "util/Reorder.util";
import { postWarningLog } from "util/ServerLogging.util";
import {
  clearCategoryPathData,
  removeSelectedPaymentMethodId,
  setSelectedPaymentMethodId,
} from "util/Storage.util";
import { showVisaSuccess } from "util/VisaSensoryBranding.util";

interface OrderConfirmationProps extends OrderSubviewProps {
  deliveryAddress?: DeliveryAddress;
  evergagePurchaseEvent: (items: PurchaseOrderItem[], orderNumber: number) => void;
}

const OrderConfirmation = (props: OrderConfirmationProps) => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const showBackToTop = useRef<HTMLInputElement | null>(null);
  const orderConfirmationContent = useRef<HTMLInputElement | null>(null);
  const orderTotals = useRef<HTMLInputElement | null>(null);
  const paymentFooter = useRef<HTMLInputElement | null>(null);
  const scrollTopPosition = useRef<HTMLInputElement | null>(null);
  const { showBoundary } = useErrorBoundary();
  const [useDesktopView] = useMediaQuery(orderConfirmationDesktopMediaQuery);

  const purchaseOrder = props.orderSession.purchaseOrder;
  const restrictedItems = props.orderSession.purchaseOrder?.items.filter((item) => item.minimumAge);
  const isPickupOrder =
    !props.orderSession.delivery && purchaseOrder?.integratedDelivery === undefined;
  const restrictedItemsExist =
    purchaseOrder?.integratedDelivery !== undefined &&
    restrictedItems !== undefined &&
    restrictedItems?.length > 0;
  const restrictedPickupItemsExist =
    restrictedItems !== undefined && restrictedItems?.length > 0 && isPickupOrder;
  const [userVehicles, setUserVehicles] = useState<UserVehicle[] | undefined>();
  const [userProfile, setUserProfile] = useState<GetUserProfileResponse | undefined>();
  const [showPickupInfo, setShowPickupInfo] = useState(false);
  const [showCurbsideInfoActionSheet, setShowCurbsideInfoActionSheet] = useState(false);
  const [showOtherTipAmountActionSheet, setShowOtherTipAmountActionSheet] = useState(false);
  const [showNoTipActionSheet, setShowNoTipActionSheet] = useState(false);
  const [orderSubmitting, setOrderSubmitting] = useState(false);
  const [paymentMethodMobileRefresh, setPaymentMethodMobileRefresh] = useState<
    boolean | undefined
  >();
  const [
    showRestrictedItemDeliveryTermsActionSheet,
    setShowRestrictedItemDeliveryTermsActionSheet,
  ] = useState<boolean>(restrictedItemsExist);
  const [showRestrictedItemPickupTermsActionSheet, setShowRestrictedItemPickupTermsActionSheet] =
    useState<boolean>(restrictedPickupItemsExist);
  const [showRestrictedItemErrorActionSheet, setShowRestrictedItemErrorActionSheet] =
    useState(false);
  const [restrictedItemErrorText, setRestrictedItemErrorText] = useState<string | undefined>();
  // If no vehicles or phone number is returned, then we need to notify the user to update this info
  const curbsideInfoNeeded =
    (userVehicles && userVehicles.length === 0) || (userProfile && userProfile.phoneNumber === "");
  const defaultVehicle = userVehicles && userVehicles[0];
  const desiredScrollPosition = 400;

  useEffect(() => {
    // Only get vehicle if this is a curbside order.
    if ("CURBSIDE" !== purchaseOrder?.pickupLocation) {
      return;
    }

    getUserVehicles().then((response) => {
      // If the user has a vehicle already set, validate that it is still a valid vehicle for them to choose (i.e. they haven't deleted it).
      if (props.orderSession.curbsideVehicle) {
        const chosenCurbsideVehicle = props.orderSession.curbsideVehicle;
        const curbsideVehicleValid =
          response.data.findIndex(
            (userVehicle: UserVehicleResponse) =>
              userVehicle.vehicleId === chosenCurbsideVehicle.vehicleId
          ) > -1;
        if (!curbsideVehicleValid) {
          props.dispatch({ type: "SET_CURBSIDE_VEHICLE", payload: undefined });
        }
      }
      setUserVehicles(response.data);
    });

    // Disable lint check due to numerous dependencies involved in useEffect hook above.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.orderSession.curbsideVehicle]);

  useEffect(() => {
    // Only get phone number if this is a curbside or delivery order.
    if (
      "CURBSIDE" === purchaseOrder?.pickupLocation ||
      purchaseOrder?.integratedDelivery !== undefined
    ) {
      getUserProfile().then((response) => {
        setUserProfile(response.data);
      });
    }

    // Disable lint check due to numerous dependencies involved in useEffect hook above.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInNativeMobileContext()) {
      let offsetTop: number;

      if (paymentFooter.current) {
        offsetTop = paymentFooter.current?.offsetTop;
      }

      const handleScroll = () => {
        const scrollPosition = window.scrollY;

        if (orderConfirmationContent.current && paymentFooter.current) {
          if (offsetTop - 70 < window.scrollY) {
            orderConfirmationContent.current.style.justifyContent = "flex-start";
            paymentFooter.current.style.position = "fixed";
          } else {
            orderConfirmationContent.current.style.justifyContent = "space-between";
            paymentFooter.current.style.position = "initial";
          }
        }

        if (showBackToTop.current) {
          showBackToTop.current.style.display =
            scrollPosition > desiredScrollPosition ? "block" : "none";
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [desiredScrollPosition]);

  useEffect(() => {
    function handleResize() {
      if (paymentFooter.current) {
        paymentFooter.current.style.position = window.innerWidth < 1110 ? "fixed" : "initial";
      }
    }

    const handleResizeDebounce = _.debounce(handleResize, 500);

    window.addEventListener("resize", handleResizeDebounce);
    return () => window.removeEventListener("resize", handleResizeDebounce);
  });

  function curbsideInfoSaved(userVehicle?: UserVehicleResponse, phoneNumber?: string): void {
    setShowCurbsideInfoActionSheet(false);
    props.dispatch({ type: "SET_CURBSIDE_VEHICLE", payload: userVehicle });
    setUserProfile({ ...userProfile, phoneNumber: phoneNumber || "" } as GetUserProfileResponse);
  }

  function updateTipSelection(tip: TipChoice): void {
    if (tip === TipChoice.Other) {
      setShowOtherTipAmountActionSheet(true);
    } else {
      updatePurchaseOrderWithTip(tip);
    }
  }

  function changePaymentPressed(): void {
    if (purchaseOrder?.paymentMode === "PAY_ONLINE") {
      if (isInNativeMobileContext()) {
        showNativeWalletInterface(purchaseOrder?.total ?? 0).then((paymentMethodId) => {
          setSelectedPaymentMethodId(paymentMethodId);

          const currentValue =
            paymentMethodMobileRefresh !== undefined ? !paymentMethodMobileRefresh : false;

          setPaymentMethodMobileRefresh(currentValue);
        });
        return;
      }

      navigate("/account/myWallet", { state: { ordering: true, amountOwed: purchaseOrder.total } });
    } else {
      navigate("/order/paymentType");
    }
  }

  function showRIEActionSheet(show: boolean): void {
    setShowRestrictedItemErrorActionSheet(show);
  }

  function showRIDTActionSheet(show: boolean): void {
    setShowRestrictedItemDeliveryTermsActionSheet(show);
  }

  function showNTActionSheet(show: boolean): void {
    setShowNoTipActionSheet(show);
  }

  function showOTAActionSheet(show: boolean): void {
    setShowOtherTipAmountActionSheet(show);
  }

  function showRIPTActionSheet(show: boolean): void {
    setShowRestrictedItemPickupTermsActionSheet(show);
  }

  function updatePurchaseOrderWithTip(tipChoice: TipChoice, otherAmount?: number): void {
    appContext.showLoading();

    const tipPercentage: number | undefined = getTipPercentageFromChoice(tipChoice);

    updatePurchaseOrder(
      {
        purchaseOrder: purchaseOrder,
        deliveryTip: { tipPercentage: tipPercentage, tipAmount: otherAmount } as DeliveryTip,
        // Existing offers selected must be passed back to the purchase order again.
        selectedOfferIds: purchaseOrder?.loyaltyDiscounts?.map(
          (discount: PurchaseOrderLoyaltyDiscount) => discount.offerId
        ),
        suggestedDeliveryTips: suggestedDeliveryTipPercentages,
      } as UpdatePurchaseOrderRequest,
      props.orderSession.orderToken,
      props.orderSession.orderSessionId
    )
      .then((response) => {
        props.dispatch({ type: "SAVE_PURCHASE_ORDER", payload: response.data.purchaseOrder });
        props.dispatch({
          type: "SET_DELIVERY_TIP_SUGGESTIONS",
          payload: response.data.suggestedDeliveryTips,
        });
        props.dispatch({ type: "SAVE_ORDER_TOKEN", payload: response.headers["order-token"] });
        if (tipPercentage !== undefined) {
          props.dispatch({ type: "SET_DELIVERY_TIP_CHOICE", payload: tipChoice });
        } else {
          props.dispatch({ type: "SET_DELIVERY_TIP_AMOUNT", payload: otherAmount });
        }
      })
      .catch((error: Error | AxiosError<BFFErrorResponse>): void => {
        if (axios.isAxiosError(error)) {
          let message: string | undefined;

          // A response of 410 (Gone) indicates an expired purchase order
          if (error.response?.status === 410) {
            message = "Your checkout has expired, please re-submit your order.";
            navigate("/order/paymentType", { replace: true });
          }

          appContext.showToast(
            "Oh Sheetz!",
            message ?? error.response?.data.message ?? "An unknown error has occurred.",
            ToastType.error,
            error.response?.config.displayErrorInModal
          );
        }
      })
      .finally(appContext.hideLoading);
  }

  function resetPurchaseOrder(): void {
    props.dispatch({ type: "CLEAR_PURCHASE_ORDER", payload: undefined });

    navigate("/order");
  }

  function scrollToDetails(): void {
    orderTotals?.current?.scrollIntoView({ behavior: "smooth" });
  }

  function scrollToTop(): void {
    scrollTopPosition?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  function submitOrderButtonPressed(
    paymentMethod?: PaymentMethod,
    mobilePayment?: MobilePayment
  ): void {
    if (!purchaseOrder) {
      showBoundary(
        new Error("Unable to submit order without a purchase order, please try checking out again.")
      );
      return;
    }
    if (!props.orderSession?.orderToken) {
      showBoundary(
        new Error("Unable to submit order without an order token, please try checking out again.")
      );
      return;
    }
    if (!props.orderSession?.dayPart) {
      showBoundary(
        new Error("Unable to submit order without dayPart, please try checking out again.")
      );
      return;
    }
    if (
      purchaseOrder.paymentMode === "PAY_ONLINE" &&
      paymentMethod === undefined &&
      mobilePayment === undefined
    ) {
      showBoundary(
        new Error(
          "Unable to submit pay-now order without a payment method. Please create a payment method first."
        )
      );
      return;
    }

    if (purchaseOrder?.pickupLocation === "CURBSIDE" && curbsideInfoNeeded) {
      setShowCurbsideInfoActionSheet(true);
      appContext.hideLoading();
      return;
    }

    let orderStartedTime: string = DateTime.local().toString();

    if (props.orderSession?.orderStartedTime) {
      orderStartedTime = props.orderSession?.orderStartedTime;
    } else {
      postWarningLog("Order started time missing during checkout, using current time instead.");
    }

    setOrderSubmitting(true);

    // Map item events
    type ShoppingBagArray = ShoppingBagItem | ReorderedShoppingBagItem | CustomizedItem;

    const itemEvents: PurchaseOrderHeader["itemEvents"] = {};
    const shoppingBagContents: ShoppingBagArray[] = [];

    [
      ...(props.orderSession.shoppingBag?.items || []),
      ...(props.orderSession.shoppingBag?.combos || []),
    ]
      .sort((a, b) => (a.id < b.id ? -1 : 1))
      .forEach((bagContent) => {
        if (isShoppingBagItem(bagContent)) {
          shoppingBagContents.push(bagContent);
        } else if (isShoppingBagCombo(bagContent)) {
          bagContent.items.forEach((combo) => shoppingBagContents.push(combo));
        }

        return undefined;
      });

    shoppingBagContents.forEach((item, index) => {
      // Map the line number of the item in the shopping bag to its ItemEvent.
      if (isCustomizedShoppingBagItem(item) && item.itemDetails.event) {
        itemEvents[index + 1] = item.itemDetails.event;
      } else if ((isReorderedShoppingBagItem(item) || isCustomizedItem(item)) && item.event) {
        itemEvents[index + 1] = item.event;
      }
    });

    getOrGenerateRavelinId()
      .then((id) => {
        // Create the payment method
        let payment: Payment | undefined = paymentMethod
          ? { paymentMethodId: paymentMethod.paymentMethodId }
          : undefined;

        if (mobilePayment) {
          payment = {
            mobilePayment: mobilePayment,
          };
        }

        const request: SubmitOrderRequest = {
          purchaseOrderHeader: {
            startedTime: orderStartedTime,
            specialDeliveryInstructions: props.orderSession.deliveryInstructions,
            pickupVehicle:
              props.orderSession.pickupLocation === "CURBSIDE"
                ? props.orderSession.curbsideVehicle || defaultVehicle
                : undefined,
            itemEvents,
          },
          purchaseOrder: purchaseOrder,
          payment,
          deviceId: id,
        };

        const dataLayer = {
          event: "purchase",
          ecommerce: {
            value: purchaseOrder.total,
            currency: "USD",
          },
          userId: props.orderSession.userId,
          transactionType: props.orderSession.inStorePickup ? "Pickup" : "Delivery",
          dateTime: DateTime.local().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
          gtmevent: "ordercomplete",
        };

        // Show the native full-screen loading indicator while submitting an order.
        if (isInNativeMobileContext()) {
          showNativeMobileLoadingIndicator();
        }

        // TODO: Remove fallbacks to empty strings for orderToken and dayPart below once error handling is updated in this component.
        submitOrder(
          request,
          props.orderSession.orderToken ?? "",
          props.orderSession.dayPart ?? "",
          props.orderSession.orderSessionId
        )
          .then((response) => {
            props.evergagePurchaseEvent(purchaseOrder.items, response.data.orderNumber);

            const payedWithVisaCard = paymentMethod?.creditCard?.cardType === "VISA";

            clearCategoryPathData();

            if (isInNativeMobileContext()) {
              setOrderSubmitting(false);
              clearOrderSession();
              finishMobileOrder({ ...response.data, isVisa: payedWithVisaCard });
              return;
            }

            const tagManagerArgs = {
              dataLayer,
            };

            // Clear previous ecommerce object
            TagManager.dataLayer({ dataLayer: { ecommerce: null } });

            // Push new purchase event to dataLayer
            TagManager.dataLayer(tagManagerArgs);

            if (payedWithVisaCard) {
              showVisaSuccess();
            }

            navigate("/order/success", {
              state: {
                orderSession: props.orderSession,
                submitOrderResponse: response.data,
                paymentMethod: paymentMethod,
              },
            });
            props.dispatch({ type: "CLEAR_ORDER_SESSION", payload: undefined });
            clearOrderSession();
            removeSelectedPaymentMethodId();
          })
          .catch((error: Error | AxiosError<BFFErrorResponse>): void => {
            setOrderSubmitting(false);
            if (isInNativeMobileContext()) {
              appContext.hideLoading();
            }

            if (axios.isAxiosError(error)) {
              let message: string | undefined;

              // Handle response code 451 (Not allowed for legal reasons) - relates to restricted item ordering.
              if (error.response?.status === 451) {
                setShowRestrictedItemErrorActionSheet(true);
                setRestrictedItemErrorText(error.response?.data.message);
                return;
              }

              // A response of 410 (Gone) indicates an expired purchase order
              if (error.response?.status === 410) {
                message = "Your checkout has expired, please re-submit your order.";
                navigate("/order/paymentType", { replace: true });
              }

              // A response of 409 (Conflict) indicates an order with the same order session has been submitted twice.
              if (error.response?.status === 409) {
                clearOrderSession();
                navigate("/order/information", { replace: true });
              }

              appContext.showToast(
                "Oh Sheetz!",
                message ?? error.response?.data.message ?? "An unknown error has occurred.",
                ToastType.error,
                error.response?.config.displayErrorInModal
              );
            }
          });
      })
      .catch((error) => showBoundary(error));
  }

  const orderConfirmationWrapperClasses = classNames("order-confirmation-wrapper", {
    "in-mobile-app": isInNativeMobileContext(),
  });

  const scrollContainerClasses = classNames("order-confirmation-scroll-container", {
    delivery: purchaseOrder?.integratedDelivery !== undefined,
  });

  const paymentFooterContainerClasses = classNames("payment-footer-container", {
    "in-mobile-app": isInNativeMobileContext(),
    absolute: !useDesktopView,
  });

  const customerInformationClasses = classNames("order-confirmation-customer-information", {
    "bottom-rip body-bg": !useDesktopView,
  });

  return (
    <>
      <div className={orderConfirmationWrapperClasses} ref={scrollTopPosition}>
        {/* Mobile View - My Savings Bar */}
        {!useDesktopView && (
          <>{purchaseOrder && <OrderTotals purchaseOrder={purchaseOrder} totalSavingBarHeader />}</>
        )}

        <div className={scrollContainerClasses}>
          {/* Desktop View - Item List & Order Totals */}
          {useDesktopView && (
            <ResponsiveLayoutContainer>
              <div className="my-order-wrapper">
                {props.orderSession.shoppingBag && (
                  <MyOrder
                    orderSession={props.orderSession}
                    resetPurchaseOrder={resetPurchaseOrder}
                    confirmation
                  />
                )}

                <div className="order-totals-wrapper" ref={orderTotals}>
                  {purchaseOrder && <OrderTotals purchaseOrder={purchaseOrder} />}
                </div>
              </div>
            </ResponsiveLayoutContainer>
          )}

          {/* Customer Information */}
          <div className="order-confirmation-content" ref={orderConfirmationContent}>
            <div className={customerInformationClasses}>
              <ResponsiveLayoutContainer>
                <OrderConfirmationDetails
                  deliveryAddress={props.deliveryAddress}
                  onShowPickupInfo={(): void => setShowPickupInfo(true)}
                  orderSession={props.orderSession}
                  purchaseOrder={purchaseOrder}
                >
                  <OrderCurbsideDetails
                    purchaseOrder={purchaseOrder}
                    curbsideVehicle={props.orderSession.curbsideVehicle}
                    userVehicles={userVehicles}
                    userProfile={userProfile}
                    onShowCurbsideInfoActionSheet={(): void => setShowCurbsideInfoActionSheet(true)}
                  />
                  {purchaseOrder?.integratedDelivery !== undefined && (
                    <OrderDeliveryInstructions
                      deliveryInstructions={props.orderSession.deliveryInstructions}
                      dispatch={props.dispatch}
                    />
                  )}
                  <OrderDeliveryPhone
                    phoneNumber={props.orderSession.deliveryPhoneNumber}
                    setUserProfile={setUserProfile}
                    userProfile={userProfile}
                    dispatch={props.dispatch}
                  />
                </OrderConfirmationDetails>

                {purchaseOrder?.redeemableOfferStatus === "FAILED" && (
                  <OrderRetry dispatch={props.dispatch} orderSession={props.orderSession} />
                )}

                {/* Mobile View - Available Rewards Button */}
                {!useDesktopView && (
                  <>
                    <AvailableRewardsButton
                      purchaseOrder={props.orderSession.purchaseOrder}
                      marginTop
                    />
                  </>
                )}
              </ResponsiveLayoutContainer>
            </div>

            {/* Mobile View - Item List & Order Totals */}
            {!useDesktopView && (
              <div className="my-order-wrapper">
                <ResponsiveLayoutContainer>
                  {props.orderSession.shoppingBag && (
                    <MyOrder
                      orderSession={props.orderSession}
                      resetPurchaseOrder={resetPurchaseOrder}
                      confirmation
                    />
                  )}

                  <div className="order-totals-wrapper" ref={orderTotals}>
                    {purchaseOrder && <OrderTotals purchaseOrder={purchaseOrder} />}
                  </div>
                </ResponsiveLayoutContainer>
              </div>
            )}

            {/* Desktop View - My Savings Bar */}
            {useDesktopView && (
              <>
                {purchaseOrder && (
                  <OrderTotals purchaseOrder={purchaseOrder} totalSavingsBarDesktop />
                )}
              </>
            )}

            {/* DesktopView - Back To Top Button */}
            {useDesktopView && (
              <div className="scroll-button" ref={showBackToTop}>
                <SheetzContainerButton onClick={() => scrollToTop()}>
                  {getIcon(IconType.arrowUp, "arrow-up")}
                </SheetzContainerButton>
              </div>
            )}

            {/* Payment Footer */}
            <div className={paymentFooterContainerClasses} ref={paymentFooter}>
              <PaymentFooter
                fetchPaymentMethods={purchaseOrder?.paymentMode === "PAY_ONLINE"}
                fetchMobilePaymentMethods
                paymentMethodMobileRefresh={paymentMethodMobileRefresh}
                subtotal={purchaseOrder?.subtotal}
                discountTotal={purchaseOrder?.discountTotal}
                virtualDiscountTotal={purchaseOrder?.virtualDiscountTotal}
                taxTotal={purchaseOrder?.taxTotal}
                total={purchaseOrder?.total}
                orderSubmitting={orderSubmitting}
                integratedDelivery={purchaseOrder?.integratedDelivery}
                deliveryTipChoice={props.orderSession.deliveryTipChoice}
                deliveryTipSuggestions={props.orderSession.deliveryTipSuggestions}
                purchaseOrder={props.orderSession.purchaseOrder}
                orderConfirmation
                onChangePaymentPressed={changePaymentPressed}
                onSubmitButtonPressed={submitOrderButtonPressed}
                onTipSelected={updateTipSelection}
                onScrollToDetails={scrollToDetails}
                resetPurchaseOrder={resetPurchaseOrder}
                showDetailsLink
              />
            </div>
          </div>
        </div>
      </div>

      {/* Action Sheets */}
      <CurbsideInfoActionSheet
        currentUserVehicle={props.orderSession.curbsideVehicle || defaultVehicle}
        onSavePressed={(userVehicle?: UserVehicle, phoneNumber?: string): void =>
          curbsideInfoSaved(userVehicle, phoneNumber)
        }
        showCurbsideInfoActionSheet={showCurbsideInfoActionSheet}
        userProfile={userProfile}
      />

      <MyPickupInfo
        show={showPickupInfo}
        close={(): void => setShowPickupInfo(false)}
        redirect={(path: string): void => {
          setShowPickupInfo(false);
          props.dispatch({ type: "CLEAR_PURCHASE_ORDER", payload: undefined });
          navigate(path, {
            state: {
              redirectOnOrderFlowFinish: "/order/confirm",
            },
          });
        }}
        orderSession={props.orderSession}
        dispatch={props.dispatch}
      />

      <CustomTipActionSheet
        setShowNoTipActionSheet={showNTActionSheet}
        setShowOtherTipAmountActionSheet={showOTAActionSheet}
        showOtherTipAmountActionSheet={showOtherTipAmountActionSheet}
        updatePurchaseOrderWithTip={updatePurchaseOrderWithTip}
      />

      <NoTipActionSheet
        showNoTipActionSheet={showNoTipActionSheet}
        setShowNoTipActionSheet={showNTActionSheet}
        setShowOtherTipAmountActionSheet={showOTAActionSheet}
      />

      <RestrictedItemActionSheet
        show={showRestrictedItemErrorActionSheet}
        errorText={restrictedItemErrorText}
        setShowRestrictedItemErrorActionSheet={showRIEActionSheet}
      />

      <RestrictedItemDeliveryTermsActionSheet
        deliveryAddress={props.deliveryAddress}
        show={showRestrictedItemDeliveryTermsActionSheet}
        setShowRestrictedItemDeliveryTermsActionSheet={showRIDTActionSheet}
      />

      <RestrictedItemPickupTermsActionSheet
        orderSession={props.orderSession}
        show={showRestrictedItemPickupTermsActionSheet}
        setShowRestrictedItemPickupTermsActionSheet={showRIPTActionSheet}
      />

      {/* DO NOT REMOVE - This is a dummy container to keep the background color of the sidebar in desktop mode */}
      <div className="order-confirmation-grid">
        <div></div>
        <div className="order-confirmation-right"></div>
      </div>
    </>
  );
};

export default OrderConfirmation;
